import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



// Actions
import { setContactNotch } from '../../actions/ui';



// Components
import FontIcon from '../ui/FontIcon';



// Helpers
import { phoneNumber, whatsapp, whatsappLink } from '../../helpers/contact';
import { analyticsPhoneCall, analyticsWhatsapp } from '../../helpers/google-analytics';
import { pixelContact } from '../../helpers/meta-pixel';



const Contact = () => {

   const dispatch = useDispatch();

   const { contactNotch } = useSelector(state => state.ui);

   const [tool, setTool] = useState(false);

   useEffect(() => {
      const showTime = 14500;
      const delayTime = 500;
      const closeTime = 5000;

      setTimeout(() => {
         dispatch(setContactNotch(true));

         setTimeout(() => {
            setTool(true);

            setTimeout(() => {
               setTool(false);
            }, closeTime);
         }, delayTime);
      }, showTime);
   }, [dispatch]);

   const handleClass = (className, active, condition) => condition ? `${className} ${active}` : className;

   const toggleNotch = (state) => dispatch(setContactNotch(state));

   const openWhatsapp = () => {
      window.open(whatsappLink, '_blank', 'noopener=true,noreferrer=true');
      analyticsWhatsapp();
      pixelContact('Whatsapp', `+${whatsapp}`);
   }

   const trackingContact = () => {
      analyticsPhoneCall();
      pixelContact('Teléfono', phoneNumber);
   }

   const trackingWhatsapp = () => {
      analyticsWhatsapp();
      pixelContact('Whatsapp', `+${whatsapp}`);
   }

   return (
      <div className={handleClass('contact-container', 'show', contactNotch)}>
         <div className={handleClass('contact', 'show', contactNotch)}>
            <div className='contact-elements'>
               <button
                  type='button'
                  className='contact-element button grey'
                  onClick={() => toggleNotch(false)}
               >
                  <FontIcon icon='faEyeSlash' />
               </button>

               <a
                  href={`tel:${phoneNumber}`}
                  className='contact-element red'
                  onMouseDown={trackingContact}
               >
                  <FontIcon icon='faPhoneFlip' />
               </a>

               <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='contact-element green'
                  href={whatsappLink}
                  onMouseDown={trackingWhatsapp}
               >
                  <FontIcon path='Fab' icon='faWhatsapp' />
               </a>
            </div>

            {/* Label */}
            <button
               type='button'
               className={handleClass('contact-button', 'hidden', contactNotch)}
               onClick={() => toggleNotch(true)}
            >
               <FontIcon icon='faAngleLeft' />
            </button>

            {/* Tooltip */}
            <div className={handleClass('contact-tooltip', 'show', tool)}>
               <div className='contact-tooltip-element'>
                  <button
                     type='button'
                     className='contact-tooltip-body'
                     onClick={openWhatsapp}
                  >
                     <FontIcon icon='faHeadset' />

                     <div className='contact-tooltip-text'>
                        <p>¿Tienes alguna duda?</p>

                        <span>¡Comunícate con nosotros!</span>
                     </div>
                  </button>

                  <div
                     className='contact-tooltip-close'
                     onClick={() => setTool(false)}
                  ><FontIcon icon='faXmark' /></div>
               </div>
            </div>
         </div>
      </div>
   );
}



export default Contact;