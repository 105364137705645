import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';



// Components
import Header from './Header';
import MarqueeRibbon from './MarqueeRibbon';



const HeaderGroup = () => {

   const ref = useRef(null);

   const { width } = useSelector(state => state.ui);

   useEffect(() => {
      if (!ref.current) return;

      const pseudoBody = document.querySelector('.body');

      const resizeObserver = new ResizeObserver(() => {
         const { height } = ref.current.getBoundingClientRect();
         
         pseudoBody.style.paddingTop = `${height.toFixed(2)}px`;
      });

      resizeObserver.observe(ref.current);

      return () => {
         resizeObserver.disconnect();
         pseudoBody.style.paddingTop = '0px';
      }
   }, []);

   if (width < 992) {
      return (
         <>
            <MarqueeRibbon />

            <Header />
         </>
      );
   }

   return (
      <div className='header-group' ref={ref}>
         <MarqueeRibbon />

         <Header />
      </div>
   );
}



export default HeaderGroup;