export const types = {
   SET_SLIDER: '[BANNERS] set slider data',
}



const initialState = {
   deskSlider: {
      mobile: null,
      desk: null,
      banners: []
   },
   mainSlider: {
      mobile: null,
      desk: null,
      banners: []
   },
   dualSlider: {
      mobile: null,
      desk: null,
      banners: []
   }
}



export const bannersReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_SLIDER:
         return {
            ...state,
            [action.payload.key]: {
               ...action.payload.slider
            }
         }

      default:
         return state;
   }
}