import { stringCapitalize } from './format';



export const formatLink = (link, children = false) => {

   const nested = link?.hijos?.map(el => ({
      id: el.id,
      text: el.titulo,
      uri: el.url_redireccion,
      extern: el.es_url_externo
   }));

   let path =  null;

   if (link.icono.fuente) {
      path = stringCapitalize(link.icono.fuente);
   }

   return {
      id: link.id,
      icon: {
         library: link.icono.libreria,
         path: path,
         icon: link.icono.icono
      },
      text: link.titulo,
      uri: link.url_redireccion,
      desk: link.escritorio,
      mobile: link.movil,
      children: (link.hijos && children) ? nested : undefined,
      extern: link.es_url_externo
   }
}