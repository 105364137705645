


export const validateOffer = (simbol = '', limit, quantity) => {
   if (simbol === '>' && quantity > limit) {
      return true;
   }

   if (simbol === '>=' && quantity >= limit) {
      return true;
   }

   if (simbol === '=' && quantity === limit) {
      return true;
   }

   if (simbol === '<=' && quantity <= limit) {
      return true;
   }

   if (simbol === '<' && quantity < limit) {
      return true;
   }

   return false;
}

/**
 * Función para obtener el string a mostrar como texto de oferta
 * @param {string} simbol Símbolo de la oferta
 * @param {number} limit Cantidad límite de la oferta
 * @param {string} name Nombre de la oferta
 * @returns {string} Texto de oferta
 */
export const showOffer = (simbol, limit, name) => {
   // Para ofertas 4x3
   if (name === '4x3') {
      return 'Precio';
   }

   // Para ofertas que apliquen a cualquier cantidad de productos
   if ((limit === 0 && simbol === '>') || (limit === 1 && simbol === '>=')) {
      return 'Precio oferta';
   }

   // Para ofertas con criterio específico
   if (simbol === '<') {
      return `Menos de ${limit} unidades`;
   }

   if (simbol === '<=') {
      return `Hasta ${limit} unidades`;
   }

   if (simbol === '=') {
      return `Comprando ${limit} unidades`;
   }

   if (simbol === '>') {
      return `Más de ${limit} unidades`;
   }

   if (simbol === '>=') {
      return `A partir de ${limit} unidades`;
   }
}