import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";



export const useSearchedValues = () => {

   const { anchos, altos, aros, loadingAttr } = useSelector(state => state.filters);

   const [params] = useSearchParams();

   const [filtersId, setFiltersId] = useState(null);

   useEffect(() => {
      if (anchos.length === 0 || altos.length === 0 || aros.length === 0 || loadingAttr) return;

      const string = params.get('medidas');

      const measures = string ? string : '';

      const splitted = measures?.split('-') || [];

      const [ancho = '', alto = '', aro = ''] = splitted;

      const filters = {}

      const data = {
         ancho: anchos.find(el => el.text === ancho),
         alto: altos.find(el => el.text === alto),
         aro: aros.find(el => el.text === aro)
      }

      Object.entries(data).forEach(([key, value]) => {
         if (value) {
            filters[key] = [value.id];
         }
      });

      setFiltersId(filters);
   }, [anchos, altos, aros, loadingAttr, params]);

   return {
      filtersList: filtersId
   }
}

export const useStringSearch = () => {

   const [params] = useSearchParams();

   const [string, setString] = useState(null);

   useEffect(() => {
      if (params.get('search')) {
         setString(params.get('search'));
      } else {
         setString(false);
      }
   }, [params]);

   return string;
}