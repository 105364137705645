export const types = {
   SET_TACS: '[TAC] set tac list',
   SET_LOADING: '[TAC] set loading state'
}



const initialState = {
   tacsList: [],
   loading: true
}



export const tacsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_TACS:
         return {
            ...state,
            tacsList: action.payload
         }

      case types.SET_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      default:
         return state;
   }
}