import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useMatch } from 'react-router-dom';



// Actions
import { toggleMobileMenu } from '../../actions/ui';



// Components
import FontIcon from '../ui/FontIcon';
import Icon from '../ui/Icon';
import Modal from '../ui/Modal';



const MobileMenu = () => {

   const dispatch = useDispatch();

   const { navigation, mobileMenu } = useSelector(state => state.ui);

   const [altNav, setAltNav] = useState([]);

   const [header, setHeader] = useState('');

   const [active, setActive] = useState(0);

   const [height, setHeight] = useState(0);

   const menuRef = useRef(null);

   useEffect(() => {
      if (menuRef.current) {
         setHeight(menuRef.current.clientHeight);
      }
   }, [menuRef]);

   const openAltNav = (id) => {
      if (id === '') {
         setHeader('');
   
         setAltNav([]);

         setActive(0);

         return;
      }

      const nav = navigation.nav.find(el => el.id === id);

      setHeader(nav.text);

      setAltNav(nav.children);

      setActive(id);
   }

   const handleActive = (condition, regular, active) => condition ? `${regular} ${active}` : regular;

   const handleBack = () => {
      setHeader('');

      setAltNav([]);

      setActive(0);
   }

   const handleClose = () => dispatch(toggleMobileMenu());

   return (
      <Modal
         className='mobile-menu-container'
         state={mobileMenu}
         close={handleClose}
      >
         <div className='mobile-menu'>
            <div className={handleActive(altNav.length > 0, 'mobile-menu-header', 'active')}>
               <button
                  type='button'
                  onClick={handleBack}
               >
                  <FontIcon icon='faArrowLeft' />
               </button>
            
               <p>{header}</p>
            </div>

            <div className='mobile-menu-body'>
               <div
                  className={handleActive(altNav.length > 0, 'mobile-menu-body-main', 'active')}
                  ref={menuRef}
               >
                  {
                     navigation.nav.filter(nav => nav.mobile).map(nav => (
                        <PrimaryElement
                           key={'p-nav-' + nav.id}
                           id={nav.id}
                           icon={nav.icon}
                           text={nav.text}
                           uri={nav.uri}
                           extern={nav.extern}
                           children={nav.children}
                           open={openAltNav}
                           active={active}
                        />
                     ))
                  }
               </div>

               <div
                  className='mobile-menu-body-secondary'
                  style={{maxHeight: (height - 5) + 'px'}}
               >
                  <div className='mobile-menu-body-secondary-elements'>
                     {
                        altNav.map(nav => (
                           <SecondaryElement
                              key={'s-nav-' + nav.id}
                              text={nav.text}
                              uri={nav.uri}
                              extern={nav.extern}
                              close={openAltNav}
                           />
                        ))
                     }
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   );
}

const PrimaryElement = ({id, icon, text, uri, extern, children = null, open, active}) => {

   const dispatch = useDispatch();
   
   const match = useMatch(uri + '/*');

   const handleActive = (isActive) => isActive ? 'mobile-menu-primary-element active' : 'mobile-menu-primary-element';

   const handleClick = (id) => open(id);

   const handleNav = () => {
      dispatch(toggleMobileMenu());

      open('');
   }

   const inner = () => (
      <>
         <div className='mobile-menu-primary-element-icon'>
            <Icon library={icon.library} path={icon.path} icon={icon.icon} />
         </div>

         <div className='mobile-menu-primary-element-text'>
            <p>{text}</p>
         </div>
      </>
   );

   if (!children && !extern) {
      return (
         <NavLink
            to={uri}
            className={({isActive}) => handleActive(isActive)}
            onClick={handleNav}
         >
            {
               inner()
            }
         </NavLink>
      );
   }

   if (!children && extern) {
      return (
         <a
            target='_blank'
            rel='noopener noreferrer'
            href={uri}
            className='mobile-menu-primary-element'
            onClick={handleNav}
         >
            {
               inner()
            }
         </a>
      );
   }

   return (
      <div
         className={handleActive(match || id === active)}
         onClick={() => handleClick(id)}
      >
         {
            inner()
         }
      </div>
   );
}

const SecondaryElement = ({text, uri, extern, close}) => {

   const dispatch = useDispatch();

   const handleActive = (isActive) => isActive ? 'mobile-menu-secondary-element active' : 'mobile-menu-secondary-element';

   const handleNav = () => {
      dispatch(toggleMobileMenu());

      close('');
   }

   if (extern) {
      return (
         <a
            target='_blank'
            rel='noopener noreferrer'
            href={uri}
            className='mobile-menu-secondary-element'
            onClick={handleNav}
         >
            <p>{text}</p>
         </a>
      );
   }

   return (
      <NavLink
         to={uri}
         className={({isActive}) => handleActive(isActive)}
         onClick={handleNav}
      >
         <p>{text}</p>
      </NavLink>
   );
}



export default MobileMenu;