import React from 'react';
import PropTypes from 'prop-types';



// Components
import FontIcon from './FontIcon';
import MdiIcon from './MdiIcon';



const Icon = ({library, path, icon, ...props}) => {

   if (library === 'mdi') {
      path = null;

      return (
         <MdiIcon icon={icon} {...props} />
      );
   }

   return (
      <FontIcon path={path} icon={icon} {...props} />
   );
}



Icon.propTypes = {
   library: PropTypes.oneOf(['mdi', 'fa']).isRequired,
   path: PropTypes.oneOf(['Fas', 'Far', 'Fab']),
   icon: PropTypes.string.isRequired
}

Icon.defaultProps = {
   path: 'Fas'
}



export default Icon;