import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';



// Custom hooks
import { useScrollbarSize } from '../../hooks/useScrollbarSize';
import { useSelector } from 'react-redux';



const Modal = ({children, className, state, close}) => {

   // TODO: legar funcionalidad de la clase modal-default a la clase modal, para solo modificar lo necesario con el parámetro className

   const modal = useRef(null);

   const { width } = useSelector(state => state.ui);

   const scrollWidth = useScrollbarSize();

   useEffect(() => {
      const header = document.querySelector('.header-group');

      if (state) {
         if (width >= 768) {
            document.body.style.paddingRight = `${scrollWidth}px`;
            if (header) {
               header.style.right = `${scrollWidth}px`;
               header.style.width = `calc(100% - ${scrollWidth}px)`;
            }
         }
         document.body.style.overflow = 'hidden';
      } else {
         if (width >= 768) {
            document.body.style.paddingRight = 0;
            if (header) {
               header.style.right = 0;
               header.style.width = '100%';
            }
         }
         document.body.style.overflow = 'auto';
      }
   }, [state, width, scrollWidth]);

   const handleShow = (status) => status ? 'modal-container active' : 'modal-container';
   
   const handleClose = (e) => {
      if (modal.current && modal.current === e.target) {
         close();
      }
   }
   
   return (
      <div className={handleShow(state)} onClick={handleClose}>
         <div className={'modal ' + className} ref={modal}>
            {children}
         </div>
      </div>
   );
}



Modal.propTypes = {
   children: PropTypes.element.isRequired,
   className: PropTypes.string,
   state: PropTypes.bool.isRequired,
   close: PropTypes.func.isRequired
}

Modal.defaultProps = {
   className: 'modal-default'
}



export default Modal;