import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';



// Actions
import { setSuggestions, startGetSuggestions } from '../../actions/search';
import { setSearchBar } from '../../actions/ui';



// Components
import FontIcon from './FontIcon';



// Helpers
import { priceFormat } from '../../helpers';



const SearchSuggestions = ({search}) => {

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const { suggestions } = useSelector(state => state.search);

   useEffect(() => {
      const timeOut = setTimeout(() => {
         const query = search.trim();
   
         dispatch(setSuggestions([]));

         if (query.length < 4) return;

         dispatch(startGetSuggestions(search));
      }, 500);

      return () => clearTimeout(timeOut);
   }, [search, dispatch]);

   const handleNav = () => dispatch(setSearchBar(false));

   const handleContainerClass = (condition) => condition ? 'search-suggestions active' : 'search-suggestions';

   const handleMore = () => {
      dispatch(setSearchBar(false));

      navigate({
         pathname: '/s/',
         replace: true,
         search: createSearchParams({
            search: search
         }).toString()
      });
   }

   return (
      <div className={handleContainerClass(suggestions.length > 1)}>
         {
            suggestions.map(p => (
               <div
                  key={'suggestion-' + p.id}
                  onMouseDown={() => navigate(`/producto/${p.uri}`)}
                  className='search-suggestion'
                  onClick={handleNav}
               >
                  <div className='search-suggestion-img'>
                     <img
                        src={p.image}
                        alt={p.name}
                     />
                  </div>

                  <div className='search-suggestion-body'>
                     <p className='search-suggestion-name'>{p.name}</p>

                     <div className='search-suggestion-data'>
                        <p className='search-suggestion-sku'>SKU: {p.sku}</p>

                        <p className='search-suggestion-price'>{priceFormat(p.price)}</p>
                     </div>
                  </div>
               </div>
            ))
         }

         {
            suggestions.length > 0 && (
               <button
                  type='button'
                  className='search-suggestions-nav'
                  disabled={search.length < 4}
                  onMouseDown={handleMore}
               >
                  <FontIcon icon='faSearch' />

                  <p>Ver más resultados</p>
               </button>
            )
         }
      </div>
   );
}



export default SearchSuggestions;