

/**
 * Función para darle formato chileno a un monto
 * @param {number} total número a formatear
 * @returns {string} monto formateado
 */
export const priceFormat = (total) => new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(total);

/**
 * Función para capitalizar un string
 * @param {string} string string a capitalizar
 * @returns {string} string capitalizado
 */
export const stringCapitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Función para capitalizar todas las palabras de un texto
 * @param {string} string texto a capitalizar
 * @returns {string}
 */
export const capitalizeAllWords = (string) => {
   const splitted = string.split(' ');

   const formated = splitted.map(word => {
      const first = word.charAt(0).toLocaleUpperCase();
      const rest = word.slice(1);

      return first + rest;
   });

   return formated.join(' ');
}

/**
 * Función para formatear el texto mostrado de un costo adicional (recargo o precio_instalacion)
 * @param {number|string|null} amount Monto que se quiere formatear
 * @returns {number|string} Monto formateado
 */
export const formatFeeAmount = (amount) => {
   if (amount === null) {
      return 'No aplica';
   }

   return Number(amount) === 0 ? 'Gratis' : priceFormat(Number(amount));
}

/**
 * Función para verificar que exista un valor y obtenerlo
 * @param {value} value Valor a verificar
 * @returns {any|null}
 */
export const valueOrNull = (value) => value ? value : null;