import ReactPixel from 'react-facebook-pixel';



/**
 * Método para disparar el tracking del evento de producto agregado al carro
 * @param {string} name Nombre del producto
 * @param {string} sku SKU del producto
 * @param {number} quantity Cantidad agregada
 * @returns {void}
 */
export const pixelAddToCart = (name, sku, quantity, currency = 'CLP') => {
   // ReactPixel.track('AddToCart', { name, sku, quantity, currency });
   ReactPixel.fbq('track', 'AddToCart', { name, sku, quantity, currency });
}

/**
 * Método para disparar el tracking del evento de contacto
 * @param {string} method Método de contacto
 * @param {number|null} value Valor de contacto (número, correo, etc)
 * @returns {void}
 */
export const pixelContact = (method, value) => ReactPixel.track('Contact', { method, value });

/**
 * Método para disparar el tracking del evento de pedido comprado
 * @param {number} orderId Id del pedido
 * @param {number} value Monto del pedido
 * @param {string} currency Moneda del pedido
 * @returns {void}
 */
export const pixelPurchasedOrder = (orderId, value, currency = 'CLP') => {
   // ReactPixel.track('Purchase', { orderId, value, currency });
   ReactPixel.fbq('track', 'Purchase', { orderId, value, currency });
}


/**
 * Método para disparar el tracking de las páginas de interés
 * @param {string} uri URI de la vista 
 * @param {string} brand nombre de la marca
 * @returns {void}
 */
export const pixelViewContent = (uri, brand) => ReactPixel.track('ViewContent', { uri, brand });