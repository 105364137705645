import { formatResult } from './results';



export const formatOrderData = (order) => {

   const orderData = {
      id: Number(order.id),
      subtotal: Number(order.monto_subtotal),
      installation: Number(order.costo_instalacion),
      dispatch: Number(order.costo_envio),
      tax: Number(order.monto_impuesto),
      discount: Number(order.monto_descuento),
      total: Number(order.monto_total_pedido),
      bill: order?.neu_datos_factura_usuario && {
         rut: order.neu_datos_factura_usuario?.empresa_rut,
         address: order.neu_datos_factura_usuario?.empresa_direccion,
         social: order.neu_datos_factura_usuario?.empresa_razon_social,
         giro: order.neu_datos_factura_usuario?.empresa_giro
      },
      productLines: order.neu_linea_pedidos.map(pr => {

         const { brand, brandImg, images, sku } = formatResult(pr.neu_producto);

         return {
            ...pr,
            quantity: Number(pr.cantidad_comprada),
            name: pr.neu_producto.nombre,
            sku,
            id: pr.producto_id,
            price: pr.precio_unidad,
            subtotal: Number(pr.subtotal),
            brand,
            brandImg,
            image: images[0]
         }
      }),
      placeId: order.neu_punto_entrega.id,
      placeTitle: `${order.neu_punto_entrega.nombre} - ${order.neu_punto_entrega.descripcion}`,
      placeAddress: order.neu_punto_entrega.direccion,
      userId: Number(order.neu_usuario.id),
      userName: order.neu_usuario.nombre,
      userEmail: order.neu_usuario.email,
      paymentStatus: order.estado_pago,
      paymentMethod: {
         id: order.neu_metodo_pago.id,
         name: order.neu_metodo_pago.nombre,
         image: order.neu_metodo_pago.imagen,
      },
      isScheduled: order.neu_agendamientos.length > 0 ? true : false
   }

   return { order: orderData }
}