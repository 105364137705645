export const types = {
   // Actualizar el título
   SET_TITLE: '[PROFILE] set section title',
   // Abrir modal para cambiar contraseña
   TOGGLE_PASSWORD: '[PROFILE] toggle password modal',
   // Abrir modal para cambiar algún atributo del perfil
   TOGGLE_ATTR: '[PROFILE] toggle attribute modal',
   // Settear la data del perfil
   SET_ORDERS_STATUS_LIST: '[PROFILE] set order status list',
   SET_ORDERS: '[PROFILE] set orders list',
   SET_ORDERS_LOADING: '[PROFILE] set orders loading state',
   SET_ORDER_DATA: '[PROFILE] set order data',
   SET_ORDER_DATA_LOADING: '[PROFILE] set order data loading state',
   // Actualizar datos del perfil
   SET_ATTRIBUTE_UPDATE_DATA: '[PROFILE] set attribute modal data',
   SET_ATTRIBUTE_UPDATE_LOADING: '[PROFILE] set attribute update loading state',
   SET_ATTRIBUTE_UPDATE_RESPONSE: '[PROFILE] set attribute update response',
}



const initialState = {
   title: '',
   passModal: false,
   attrModal: false,
   // ORDERS
   statusList: [],
   orders: [],
   total: null,
   pages: null,
   loadingOrders: true,
   // ORDER LOG
   orderData: null,
   loading: true,
   // PROFILE
   key: null,
   attrTitle: null,
   reduxKey: null,
   loadingAttr: false,
   attrMsg: null,
   attrError: false
}



export const profileReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_TITLE:
         return {
            ...state,
            title: action.payload
         }

      case types.TOGGLE_PASSWORD:
         return {
            ...state,
            passModal: !state.passModal
         }

      case types.TOGGLE_ATTR:
         return {
            ...state,
            attrModal: !state.attrModal
         }

      case types.SET_ORDERS:
         return {
            ...state,
            orders: action.payload.orders,
            total: action.payload.total,
            pages: action.payload.pages
         }

      case types.SET_ORDERS_LOADING:
         return {
            ...state,
            loadingOrders: action.payload
         }

      case types.SET_ORDER_DATA:
         return {
            ...state,
            orderData: action.payload
         }

      case types.SET_ORDER_DATA_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      case types.SET_ATTRIBUTE_UPDATE_DATA:
         return {
            ...state,
            key: action.payload.key,
            attrTitle: action.payload.title,
            reduxKey: action.payload.reduxKey
         }

      case types.SET_ATTRIBUTE_UPDATE_LOADING:
         return {
            ...state,
            loadingAttr: action.payload
         }

      case types.SET_ATTRIBUTE_UPDATE_RESPONSE:
         return {
            ...state,
            attrMsg: action.payload.response,
            attrError: action.payload.error
         }

      case types.SET_ORDERS_STATUS_LIST:
         return {
            ...state,
            statusList: action.payload
         }

      default:
         return state;
   }
}