import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSearchParams, Link, NavLink, useMatch, useNavigate } from 'react-router-dom';



// Actions
import { setSearchBar } from '../../actions/ui';
import { startLogout } from '../../actions/auth';
import { startSetCartData } from '../../actions/cart';



// Components
import FontIcon from './FontIcon';
import SearchSuggestions from './SearchSuggestions';



// Custom hooks
import { useGeoLocation } from '../../hooks/useGeoLocation';
import { useStringSearch } from '../../hooks/useStrictSearch';



// Helpers
import { analyticsPhoneCall } from '../../helpers/google-analytics';
import { pixelContact } from '../../helpers/meta-pixel';
import { phoneNumber, phoneNumberShow } from '../../helpers/contact';



const Header = () => {

   const { width } = useSelector(state => state.ui);
   
   const dispatch = useDispatch();
   
   useEffect(() => {
      dispatch(startSetCartData());
   }, [dispatch]);

   if (width <= 992) {
      return (
         <Mobile />
      );
   }

   return (
      <Desktop />
   );
}

const Mobile = () => {

   let match = useMatch('/');

   const dispatch = useDispatch();

   const { width } = useSelector(state => state.ui);

   const { cart } = useSelector(state => state.cart);

   const handleLogo = () => (match && width <= 768) ? 'logo-blanco.svg' : 'logo-oscuro.svg';

   const handleHeader = () => (match && width <= 768) ? 'header' : 'header alt';

   const openSearchBar = () => dispatch(setSearchBar(true));
   
   return (
      <header className='header-container'>
         <div className={handleHeader()}>
            <button
               type='button'
               className='header-btn'
               onClick={openSearchBar}
            >
               <FontIcon icon='faSearch' />
            </button>

            <Link
               to={'/'}
               className='header-brand'
            >
               <img
                  src={process.env.PUBLIC_URL + '/assets/images/' + handleLogo()}
                  alt='brand logo'
               />
            </Link>

            <Link to={'/carrito'} className='header-btn header-cart-btn'>
               <FontIcon icon='faShoppingCart' />

               {
                  cart.length > 0 && (
                     <span className='header-cart-qty'>{cart.length}</span>
                  )
               }
            </Link>
         </div>
      </header>
   );
}

const Desktop = () => {

   const navigate = useNavigate();

   const dispatch = useDispatch();

   const { name } = useSelector(state => state.auth);
   const { navigation } = useSelector(state => state.ui);
   const { cart } = useSelector(state => state.cart);

   const inputRef = useRef();

   const [search, setSearch] = useState('');
   const [navId, setNavId] = useState(null);

   const querySearch = useStringSearch();

   const { commune } = useGeoLocation();
   
   useEffect(() => {
      querySearch	&& setSearch(querySearch);
   }, [querySearch]);
   
   const logout = () => dispatch(startLogout());

   const handleSearch = (e) => {
      e.preventDefault();

      if (search.length > 2) {
         inputRef.current.blur();

         navigate({
            pathname: '/s/',
            replace: true,
            search: createSearchParams({
               search,
            }).toString()
         });
      }
   }

   const handleNestedNav = (id) => setNavId(id !== navId ? id : null);

   const handleActive = ({isActive}) => isActive ? 'header-nav-element active' : 'header-nav-element';

   const handleActiveNested = (id) => id === navId ? 'header-nav-element active' : 'header-nav-element';

   const trackingContact = () => {
      analyticsPhoneCall();
      pixelContact('Teléfono', phoneNumber);
   }

   return (
      <div className='header-container'>
         <div className='header-desk'>
            <div className='header-top'>
               <Link
                  to='/'
                  className='header-top-brand'
               >
                  <img
                     src={process.env.PUBLIC_URL + '/assets/images/logo-oscuro.svg'}
                     alt='brand logo'
                  />
               </Link>

               <form
                  className='header-top-search'
                  onSubmit={handleSearch}
               >
                  <input
                     type='text'
                     autoComplete='off'
                     placeholder='¿Qué estaba buscando?'
                     value={search}
                     onChange={(e) => setSearch(e.target.value)}
                     ref={inputRef}
                  />

                  <button
                     type='submit'
                  >
                     <FontIcon icon='faSearch' />
                  </button>

                  <SearchSuggestions search={search} />
               </form>

               <div className='header-top-buttons'>
                  <div className='header-top-button desktop-1'>
                     <FontIcon icon='faPhone' />

                     <div className='header-top-button-body'>
                        <p className='header-top-button-main'>Contáctanos</p>

                        <a
                           href={`tel:${phoneNumber}`}
                           className='header-top-button-secondary'
                           onMouseDown={trackingContact}
                        >{phoneNumberShow}</a>
                     </div>
                  </div>

                  <div className='header-top-button desktop-2'>
                     <FontIcon icon='faLocationDot' />

                     <div className='header-top-button-body'>
                        <p className='header-top-button-main'>Tu ubicación</p>

                        <span className='header-top-button-secondary'>{ commune ? commune : 'No registrada'}</span>
                     </div>
                  </div>

                  <div className='header-top-button'>
                     <FontIcon icon='faUser' />

                     {
                        !name
                        ?
                           (
                              <div className='header-top-button-body'>
                                 <Link to='/login' className='header-top-button-main'>Iniciar sesión</Link>

                                 <Link to='/registro' className='header-top-button-secondary'>Registrarme</Link>
                              </div>
                           )
                        :
                           (
                              <div className='header-top-button-body'>
                                 <Link to='/perfil' className='header-top-button-main no-wrap'>{name}</Link>

                                 <button
                                    className='header-top-button-secondary'
                                    type='button'
                                    onClick={logout}
                                 >Cerrar sesión</button>
                              </div>
                           )
                     }
                  </div>

                  <Link
                     to='/carrito'
                     className='header-top-button header-cart-btn'
                  >
                     <FontIcon icon='faShoppingCart' />

                     {
                        cart.length > 0 && (
                           <span className='header-top-cart-qty'>{cart.length}</span>
                        )
                     }
                  </Link>
               </div>
            </div>

            <div className='header-nav'>
               {
                  navigation.nav.filter(nav => nav.desk).map(nav => (
                     !nav.extern
                     ?
                     (
                        <NavLink
                           key={'nav-desk-' + nav.id}
                           to={nav.children ? '#' : nav.uri}
                           onClick={ nav.children ? () => handleNestedNav(nav.id) : null}
                           className={ nav.children ? () => handleActiveNested(nav.id) : handleActive }
                        >{nav.text}</NavLink>
                     )
                     :
                     (
                        <a
                           key={'nav-desk-' + nav.id}
                           target='_blank'
                           rel='noopener noreferrer'
                           href={nav.uri}
                           className='header-nav-element'
                        >{nav.text}</a>
                     )
                  ))
               }

            </div>

            <div className={`header-nav-children ${!!navId && 'active'}`}>
               {
                  (() => {
                     const find = navigation.nav.find(nav => nav.id === navId) || { children: [] };

                     return find.children.map(nav => (
                        !nav.extern
                        ?
                        (
                           <NavLink
                              key={'nav-desk-sub-' + nav.id}
                              to={nav.children ? '#' : nav.uri}
                              className={handleActive}
                           >{nav.text}</NavLink>
                        )
                        :
                        (
                           <a
                              key={'nav-desk-sub-' + nav.id}
                              target='_blank'
                              rel='noopener noreferrer'
                              href={nav.uri}
                              className='header-nav-element'
                           >{nav.text}</a>
                        )
                     ))
                  })()
               }
            </div>
         </div>
      </div>
   );
}



export default Header;