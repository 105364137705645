


export const formatOffice = (office) => ({
   id: office.id,
   name: office.nombre,
   description: office.descripcion,
   address: office.direccion,
   image: office.imagen,
   mapURL: office.url_mapa,
   phone: '+56 9 12345678',
   commune: office.comuna.nombre,
   region: {
      id: office.comuna.neu_region.id,
      name: office.comuna.neu_region.nombre,
      text: office.comuna.neu_region.nombre,
      value: `${office.comuna.neu_region.id}`
   }
});


/**
 * Función para formatear una lista de oficinas o aliados para ser usado dentro de un select
 * @param {Array} offices 
 */
export const formatOfficesAsOptions = (offices) => offices.map(off => ({
   ...off,
   value: `${off.id}`,
   text: `${off.name} - ${off.description}`
}));