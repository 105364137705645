import { useEffect } from 'react';
import { useDispatch } from 'react-redux';



// Actions
import { setWidthSize } from '../actions/ui';



const getWindowDimensions = () => {
   const { innerWidth: width, innerHeight: height } = window;

   return { width, height };
}

export const useWindowDimensions = () => {

   const dispatch = useDispatch();

   useEffect(() => {
      const handleResize = () => {
         const { width } = getWindowDimensions();

         dispatch(setWidthSize(width));
      }

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
   }, [dispatch]);
}