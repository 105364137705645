export const types = {
   SET_RANDOM_PRODUCTS: '[UI] set random products',
   
   SET_BRANDS: '[UI] set featured brands',
   
   SET_CONTROLLED_COMPONENT: '[UI] set controlled component data',
}



const initialState = {
   // Index
   products: [],
   // Featured brands
   brands: [],
   // Purchase steps
   purchaseSteps: {
      mobile: null,
      desk: null,
      data: []
   },
   // Trust info
   trustInfo: {
      mobile: null,
      desk: null,
      data: []
   },
}



export const mainReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_RANDOM_PRODUCTS:
         return {
            ...state,
            products: action.payload
         }

      case types.SET_BRANDS:
         return {
            ...state,
            brands: action.payload
         }

      case types.SET_CONTROLLED_COMPONENT:
         return {
            ...state,
            [action.payload.key]: {
               ...state[action.payload.key],
               mobile: action.payload.mobile,
               desk: action.payload.desk,
               data: action.payload.data
            }
         }

      default:
         return state;
   }
}