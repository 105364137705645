export const types = {
   // Window width
   SET_WIDTH: '[UI] set width size',
   // Mobile menu
   MOBILE_MENU: '[UI] toggle mobile menu',
   // Contact notch
   CONTACT_NOTCH: '[UI] set contact notch',
   // Search bar
   SEARCH_BAR: '[UI] set search bar',
   
   // Marquee
   SET_MARQUEE: '[UI] set marquee data',
   // Navigation links
   SET_NAVIGATION: '[UI] set navigation links data',
   // Geolocation data
   SET_LOCATION: '[UI] set location data',

   // Index
   SET_RANDOM_PRODUCTS: '[UI] set random products',
   // Brands data
   SET_BRANDS: '[UI] set featured brands',
   // PURCHASE STEPS
   SET_PURCHASE_STEPS: '[UI] set purchase steps',
   // TRUST INFO
   SET_TRUST_INFO: '[UI] set trust info',
}



const initialState = {
   // Window size
   width: window.innerWidth,

   // Navigation links
   navigation: {
      nav: [],
      col1: [],
      col2: [],
      col3: []
   },

   // hud elements

   mobileMenu: false,
   contactNotch: true,
   searchBar: false,

   marquee: {
      mobile: null,
      desk: null,
      data: []
   },

   // Geolocation
   location: {
      commune: null,
      communeId: null,
      region: null,
      regionId: null
   },
}



export const uiReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.MOBILE_MENU:
         return {
            ...state,
            mobileMenu: !state.mobileMenu
         }

      case types.CONTACT_NOTCH:
         return {
            ...state,
            contactNotch: action.payload
         }

      case types.SEARCH_BAR:
         return {
            ...state,
            searchBar: action.payload
         }

      case types.SET_NAVIGATION:
         return {
            ...state,
            navigation: {
               ...state.navigation,
               [action.payload.key]: action.payload.links
            }
         }

      case types.SET_LOCATION:
         return {
            ...state,
            location: {
               commune: action.payload.commune,
               communeId: action.payload.communeId,
               region: action.payload.region,
               regionId: action.payload.regionId,
            }
         }

      case types.SET_MARQUEE:
         return {
            ...state,
            marquee: {
               mobile: action.payload.mobile,
               desk: action.payload.desk,
               data: action.payload.data
            }
         }

      case types.SET_WIDTH:
         return {
            ...state,
            width: action.payload
         }

      default:
         return state;
   }
}