export const types = {
   // Obtener listado de productos con oferta
   SET_PRODUCTS: '[OFFERS] set products list',
   SET_LOADING: '[OFFERS] set loading state'
}



const initialState = {
   products: [],
   total: null,
   pages: null,
   loading: true
}



export const offersReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_PRODUCTS:
         return {
            ...state,
            products: action.payload.products,
            total: action.payload.total,
            pages: action.payload.pages
         }

      case types.SET_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      default:
         return state;
   }
}