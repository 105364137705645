import React, { Suspense } from 'react';



// Components
import LoadingPage from './LoadingPage';



const LoadingLazy = ({element}) => {

   return (
      <Suspense
         fallback={<LoadingPage />}
      >{element}</Suspense>
   );
}



export default LoadingLazy;