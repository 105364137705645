export const types = {
   SET_TRACKING: '[TRACKING] set tracking string value',
   SET_ORDERS_STATUS_LIST: '[TRACKING] set order status list',
   SET_ORDER_DATA: '[TRACKING] set order data',
   SET_ORDER_DATA_LOADING: '[TRACKING] set order data loading state',
}



const initialState = {
   tracking: '',
   statusList: [],
   orderData: null,
   loading: false,
}



export const trackingReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_TRACKING:
         return {
            ...state,
            tracking: action.payload
         }

      case types.SET_ORDER_DATA:
         return {
            ...state,
            orderData: action.payload
         }

      case types.SET_ORDER_DATA_LOADING:
         return {
            ...state,
            loading: action.payload
         }

      case types.SET_ORDERS_STATUS_LIST:
         return {
            ...state,
            statusList: action.payload
         }

      default:
         return state;
   }
}