import React, { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { useDispatch, useSelector } from 'react-redux';



// Actions
import { startGetMarquee } from '../../actions/ui';



const MarqueeRibbon = () => {
   
   const dispatch = useDispatch();

   const { marquee: { mobile, desk, data }, width } = useSelector(state => state.ui);

   useEffect(() => {
      dispatch(startGetMarquee());
   }, [dispatch]);

   if (data.length < 1) {
      return (<></>);
   }

   if (width < 768 && !mobile) {
      return (<></>);
   }

   if (width >= 768 && !desk) {
      return (<></>);
   }

   return (
      <section className='marquee-ribbon-container'>
         <Marquee
            speed={80}
            gradient={false}
         >
            {
               data.map(el => (
                  <p
                     key={'marquee-' + el.id}
                     className='marquee-ribbon-element'
                  >{el.text}</p>
               ))
            }
         </Marquee>
      </section>
   );
}



export default MarqueeRibbon;