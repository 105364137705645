import { stringCapitalize } from './format';
import { apiURL } from './request';



// Obtener la información de la petición de búsqueda
export const getResults = (response) => {
   const { data, total, last_page } = response.datos.productos;

   const formated = data.map(p => formatResult(p));

   return {
      results: formated,
      total,
      pages: last_page
   }
}

export const formatResult = (product, {view = false, recommended  = false} = {}) => {

   const attributesList = Object.fromEntries(product.neu_producto_atributos.map(att => [
      att.neu_valor_atributo.neu_clave_atributo.nombre, att.neu_valor_atributo.valor
   ]));

   const stock_erp = product?.stock_disponible_erp ?? false

   // Atributos de las especificaciones
   const attributes = product.neu_producto_atributos.filter(att => att.neu_valor_atributo.neu_clave_atributo.incluir_en_especificaciones);

   const formatedAttrs = attributes.map(attr => {
 
      let value = '';

      if (!!attr.neu_valor_atributo.codigo && attr.neu_valor_atributo.codigo !== attr.neu_valor_atributo.valor) {
         value = value + `${attr.neu_valor_atributo.codigo} = `;
      }

      value = value + `${attr.neu_valor_atributo.valor} `;

      if (attr?.neu_valor_atributo?.unidad?.simbolo) {
         value = value + attr.neu_valor_atributo?.unidad?.simbolo;
      }

      value = value.trim();

      const iconPath = attr.neu_valor_atributo.neu_clave_atributo.icono.fuente;

      return {
         key: attr.neu_valor_atributo.neu_clave_atributo.nombre,
         keyName: attr.neu_valor_atributo.neu_clave_atributo.nombre_mostrar,
         value,
         description: attr.neu_valor_atributo.neu_clave_atributo.informacion,
         url: attr.neu_valor_atributo.neu_clave_atributo.link_blog,
         icon: {
            library: attr.neu_valor_atributo.neu_clave_atributo.icono.libreria,
            path: iconPath ? stringCapitalize(iconPath) : iconPath,
            icon: attr.neu_valor_atributo.neu_clave_atributo.icono.icono
         },
         isHighlighted: attr.neu_valor_atributo.neu_clave_atributo.valor_resaltado,
      }
   });
   
   // Marca
   const brandAttr = product.neu_producto_atributos.find(att => att.neu_valor_atributo.neu_clave_atributo.nombre === 'marca');

   const brand = {
      id: brandAttr?.neu_valor_atributo?.id,
      img: brandAttr?.neu_valor_atributo?.logo
         ? `${apiURL}/storage/${brandAttr.neu_valor_atributo.logo}`
         : `${process.env.PUBLIC_URL}/assets/images/no-image/brand.png`,
      alt: brandAttr?.neu_valor_atributo?.nombre,
      name: brandAttr?.neu_valor_atributo?.nombre,
      slug: brandAttr?.neu_valor_atributo?.slug
   }

   // Atributos de los detalles (especificaciones de la vista de producto)
   const details = product.neu_producto_atributos.map(attr => ({
      id: attr.atributo_valor_id,
      key: attr.neu_valor_atributo.neu_clave_atributo.nombre.replaceAll('_', ' '),
      value: `${attr.neu_valor_atributo.valor} ${attr?.neu_valor_atributo?.unidad?.simbolo || ''}`.trim(),
      isHighlighted: attr.neu_valor_atributo.neu_clave_atributo.valor_resaltado,
      comparisonKey: attr.neu_valor_atributo.neu_clave_atributo.nombre,
      comparisonValue: attr.neu_valor_atributo.valor,
      comparison: !!attr.neu_valor_atributo.neu_clave_atributo.atributo_comparable,
      filter: !!attr.neu_valor_atributo.neu_clave_atributo.atributo_filtrable,
      img: attr.neu_valor_atributo.logo
   }));

   // Adicionales
   const perks = product.neu_producto_adicionales.map(perk => ({
      id: Number(perk.adicional_id),
      name: perk.neu_adicional.nombre,
      icon: {
         library: perk.neu_adicional?.icono?.libreria,
         path: perk.neu_adicional?.icono?.fuente ? stringCapitalize(perk.neu_adicional?.icono?.fuente) : null,
         icon: perk.neu_adicional?.icono?.icono,
      }
   }));

   // Oferta
   let offerData = {
      limit: 0,
      simbol: '',
      name: ''
   }
   
   if (product.neu_oferta_producto.length > 0) {
      offerData = {
         limit: product.neu_oferta_producto[0].neu_oferta.cantidad_limite,
         simbol: product.neu_oferta_producto[0].neu_oferta.neu_criterio_comparacion.simbolo,
         name: product.neu_oferta_producto[0].neu_oferta.nombre
      }
   }    

   // Imágenes
   const images = product.neu_producto_imagenes.map(image => ({
      id: image.id,
      img: `${apiURL}/storage/${image.imagen}`,
      alt: image.alt
   }));

   const placeHolder = [{
      id: 'placeholder-product',
      img: `${process.env.PUBLIC_URL}/assets/images/no-image/product.png`,
      alt: 'Product placeholder'
   }];

   // Métodos de pago
   const paymentMethods = product.neu_metodo_pago_producto.map(method => method.neu_metodo_pago.nombre.toLocaleLowerCase());

   // Stock propio
   const ownStock = Number(product.stock_propio);

   // Etiquetas
   const filteredTags = product.neu_etiqueta_producto.filter(tag => tag?.neu_etiqueta?.imagen && tag.neu_etiqueta.imagen !== '');

   const tags = filteredTags.map(tag => ({
      id: tag.id,
      name: tag.neu_etiqueta.nombre,
      image: tag.neu_etiqueta.imagen
   }));

   // sku
   const sku = (() => {
      if (product.sku_a_mostrar.includes('(')) {
         const splitted = product.sku_a_mostrar.split('(');

         return splitted[0];
      }

      return product.sku_a_mostrar;
   })();

   return {
      ...product,
      price: Number(product.precio_final),
      offer: product.precio_oferta ? Number(product.precio_oferta) : null,
      attributesList,
      attributes: formatedAttrs,
      brand,
      perks,
      images: images.length > 0 ? images : placeHolder,
      offerData,
      details: view ? details : undefined,
      paymentMethods,
      ownStock,
      tags,
      sku,
      recommended: (recommended || stock_erp) && (!attributesList.neumatico_carga || attributesList.neumatico_carga !== 'LTR')
   }
}