export const types = {
   // Attributes
   SET_ATTRIBUTES_LOADING: '[FILTERS] set attributes loading value',
   SET_ATTRIBUTES: '[FILTERS] set attributes values',
}




const initialState = {
   // Attributes
   aros: [],
   anchos: [],
   altos: [],
   loadingAttr: true,
}



export const filtersReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_ATTRIBUTES:
         return {
            ...state,
            anchos: action.payload.anchos,
            altos: action.payload.altos,
            aros: action.payload.aros
         }

      case types.SET_ATTRIBUTES_LOADING:
         return {
            ...state,
            loadingAttr: action.payload
         }

      default:
         return state;
   }
}