import { stringCapitalize } from '../helpers/format';

export const types = {
   // Data del evento
   SET_EVENT: '[EVENT] set event data',

   SET_LOADING: '[EVENT] set loading state',

   SET_PRODUCTS: '[EVENT] set products data'
}



const initialState = {
   loadingEvent: true,
   event: null,

   // Products
   products: [],
   total: null,
   pages: null,
   loadingProducts: true,
}



export const eventReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_EVENT:
         return {
            ...state,
            event: action.payload
         }

      case types.SET_PRODUCTS:
         return {
            ...state,
            products: action.payload.products,
            total: action.payload.total,
            pages: action.payload.pages
         }

      default:
         return state;
   }
}