import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';



// Actions
import { startKeepLogged } from '../../actions/auth';
import { startGetNavigation } from '../../actions/ui';



// Components
import LoadingPage from '../ui/LoadingPage';



const PersistentLog = ({children}) => {

   const dispatch = useDispatch();

   const { loadingData } = useSelector(state => state.auth);

   useEffect(() => {
      dispatch(startKeepLogged());
      // Obtener datos de la navegación (menues)
      dispatch(startGetNavigation());
   }, [dispatch]);

   if (loadingData) {
      return (
         <LoadingPage />
      )
   }

   return (
      <>{children}</>
   );
}



export default PersistentLog;